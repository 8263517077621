import request from '@/utils/request.js'
export const urlPath = '/webui/'

// 查询页面列表信息
export const queryPageList = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'uiPage',
    params: {
      ...data
    }
  })
}

// 依据ID查询页面信息
export const queryPageById = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'uiPage',
    params: {
      ...data
    }
  })
}

export const addPage = (data) => {
  return request({
    method: 'POST',
    url: urlPath + 'uiPage',
    data
  })
}

export const editPage = (data) => {
  return request({
    method: 'PUT',
    url: urlPath + 'uiPage',
    data
  })
}

export const deletePage = (data) => {
  return request({
    method: 'DELETE',
    url: urlPath + 'uiPage',
    data
  })
}
