import request from '@/utils/request.js'
import { urlPath } from './uiPage'

export const queryCascader = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'uiCascader',
    params: {
      ...data
    }
  })
}
