import request from '@/utils/request.js'
import { urlPath } from './uiPage'

// 查询元素列表信息
export const queryElementList = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'uiElement',
    params: {
      ...data
    }
  })
}

// 依据ID查询元素信息
export const queryElementById = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'uiElement',
    params: {
      ...data
    }
  })
}

// 添加元素
export const addElement = (data) => {
  return request({
    method: 'POST',
    url: urlPath + 'uiElement',
    data
  })
}

// 编辑元素
export const editElement = (data) => {
  return request({
    method: 'PUT',
    url: urlPath + 'uiElement',
    data
  })
}

// 编辑元素
export const deleteElement = (data) => {
  return request({
    method: 'DELETE',
    url: urlPath + 'uiElement',
    data
  })
}
