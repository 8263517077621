<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        元素管理
        <el-tooltip class="item" effect="dark" content="点击表格行内容跳转" placement="right">
          <i class="el-icon-info"></i>
        </el-tooltip>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <div slot="header" class="clearfix" style="overflow: hidden;">
        <!-- <span>卡片名称</span> -->
        <el-button type="primary" @click="handleAdd">新增元素</el-button>
      </div>
       <!-- 当前接口信息展示 -->
       <el-table :data="tableDataElement" style="width: 100%">
        <el-table-column
          prop="element_id"
          label="ID">
        </el-table-column>
        <el-table-column
          prop="element_name"
          label="用例名称">
        </el-table-column>
        <el-table-column
          prop="project_id"
          label="所属项目">
        </el-table-column>
        <el-table-column
          prop="module_id"
          label="所属模块">
        </el-table-column>
        <el-table-column
          prop="page_id"
          label="所属页面">
        </el-table-column>
        <el-table-column
          prop="description"
          label="元素描述">
        </el-table-column>
        <el-table-column
          prop="status"
          label="元素状态">
        </el-table-column>
        <el-table-column
          prop="created_time"
          label="创建时间">
        </el-table-column>
        <el-table-column
          label="操作"
          width="150">
          <template slot-scope="scope">
            <el-button round type="warning" @click.stop="handleEdit(scope.row)">编辑</el-button>
            <el-button round type="danger" @click.stop="handleDel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 测试接口分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInfo.currentPage"
      :page-sizes="[1, 10, 30, 50]"
      :page-size="pageInfo.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageInfo.total_count">
    </el-pagination>

    <!-- 新增编辑弹窗 -->
    <el-dialog
      :title="isAdd?'新增接口':'编辑接口'"
      :visible.sync="dialogVisibleElement"
      width="50%"
      @close="closeDialogHandle">
      <el-form :model="ruleFormElement" :rules="FormRulesElement" ref="ruleFormRefElement" label-width="120px" class="demo-ruleForm">
        <el-form-item v-if="!isAdd" label="元素ID">
          <el-input v-model="ruleFormElement.element_id" disabled></el-input>
        </el-form-item>
        <el-form-item label="元素名称" prop="element_name">
          <el-input v-model="ruleFormElement.element_name"></el-input>
        </el-form-item>
        <el-form-item label="元素查找方式" prop="page_name">
          <!-- <el-input v-model="ruleFormElement.element_find_type"></el-input> -->
          <el-select v-model="ruleFormElement.element_find_type" placeholder="请选择">
            <el-option
              v-for="item in optionsElementFindType"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="元素定位值" prop="page_name">
          <el-input v-model="ruleFormElement.element_find_value" placeholder="填写id、name或者xpath值"></el-input>
        </el-form-item>
        <el-form-item label="所属项目/模块/页面" prop="page_name">
          <el-cascader
            v-model="pageCascaderValue"
            :options="pageCascaderOptions"
            :props="{ expandTrigger: 'hover', label: 'name', value: 'id'}"
            @change="pageHandleChange"
            style="width: 100%;">
          </el-cascader>
        </el-form-item>
        <el-form-item label="页面状态" prop="page_name">
          <el-switch v-model="ruleFormElement.status" active-value="1" inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="页面描述" prop="description">
          <el-input type="textarea" v-model="ruleFormElement.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleElement = false">取 消</el-button>
        <el-button v-if="isAdd" type="primary" @click="processAddElement">新 增</el-button>
        <el-button v-if="!isAdd" type="primary" @click="processEditElement">编 辑</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { queryElementList, addElement, editElement, deleteElement } from '@/services/uiElement.js'
import { queryCascader } from '@/services/uiCascader.js'
export default {
  name: 'UiElement',
  data () {
    return {
      isAdd: true,
      tableDataElement: [],
      pageInfo: {
        currentPage: 1,
        size: 10,
        total_count: null
      },
      dialogVisibleElement: false,
      ruleFormElement: {},
      FormRulesElement: {
        element_name: [
          { required: true, message: '请输环境名称', trigger: 'blur' },
          { min: 3, max: 20, message: '长度在 3到 20 个字符', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请输入环境描述', trigger: 'blur' },
          { min: 3, max: 100, message: '长度在 3 到 100 个字符', trigger: 'blur' }
        ]
      },
      pageCascaderValue: [],
      pageCascaderOptions: [],
      optionsElementFindType: [
        {
          value: 'XPATH',
          label: 'XPATH'
        }
      ]
    }
  },
  created () {
    this.loadGetElementList()
  },
  methods: {
    async loadGetElementList () {
      const queryObj = {
        query_type: 'query_list',
        page: this.pageInfo.currentPage,
        size: this.pageInfo.size
      }
      const { data: res } = await queryElementList(queryObj)
      if (res.status === 200) {
        this.tableDataElement = res.data
        this.pageInfo.total_count = res.total_count
      }
    },
    async loadGetCascader () {
      const queryObj = { cascader_type: 'page' }
      const { data: res } = await queryCascader(queryObj)
      if (res.status === 200) {
        this.pageCascaderOptions = res.data
      }
    },
    // 接口信息 分页处理
    handleSizeChange (val) {
      this.pageInfo.size = val
      this.loadGetElementList()
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      this.pageInfo.currentPage = val
      this.loadGetElementList()
      console.log(`当前页: ${val}`)
    },
    handleAdd () {
      console.log('新增动作')
      this.isAdd = true
      this.ruleFormElement = {}
      this.pageCascaderValue = []
      this.loadGetCascader()
      this.dialogVisibleElement = true
    },
    handleEdit (rowData) {
      console.log('编辑动作')
      this.isAdd = false
      this.ruleFormElement = rowData
      this.loadGetCascader()
      this.pageCascaderValue = [rowData.project_id, rowData.module_id, rowData.page_id]
      this.dialogVisibleElement = true
    },
    handleDel (rowData) {
      console.log('删除动作')
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const deleteObj = { element_id: rowData.element_id }
        const { data: res } = await deleteElement(deleteObj)
        if (res.status === 200) {
          this.$message.success('删除成功')
          this.loadGetElementList()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async processAddElement () {
      const queryObj = this.ruleFormElement
      const { data: res } = await addElement(queryObj)
      if (res.status === 200) {
        this.dialogVisibleElement = false
        this.$message.success('添加成功')
        this.loadGetElementList()
      }
    },
    async processEditElement () {
      const editObj = this.ruleFormElement
      const { data: res } = await editElement(editObj)
      if (res.status === 200) {
        this.dialogVisibleElement = false
        this.loadGetElementList()
        this.$message.success('编辑成功')
      }
    },
    closeDialogHandle () {
      this.$refs.ruleFormRefElement.resetFields()
      this.loadGetElementList()
    },
    pageHandleChange () {
      // this.pageCascaderValue 值为列表形式，最后一个值为页面id，将它赋予所属页面
      // this.ruleFormPage.project_id = this.pageCascaderValue[0]
      // this.ruleFormPage.module_id = this.pageCascaderValue[1]
      this.ruleFormElement.page_id = this.pageCascaderValue[this.pageCascaderValue.length - 1]
    }
  }
}
</script>

<style>

</style>
